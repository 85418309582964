import { render, staticRenderFns } from "./DataDetectionTask.vue?vue&type=template&id=697ff161&scoped=true&"
import script from "./DataDetectionTask.vue?vue&type=script&lang=js&"
export * from "./DataDetectionTask.vue?vue&type=script&lang=js&"
import style0 from "./DataDetectionTask.vue?vue&type=style&index=0&id=697ff161&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.10.0@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "697ff161",
  null
  
)

export default component.exports
<!--
 * @Author: 墨凡 oncwnuN3VU3V55c1lqO_6llf5DtU@git.weixin.qq.com
 * @Date: 2022-08-22 10:35:43
 * @LastEditors: zll zll1217168026@163.com
 * @LastEditTime: 2022-10-10 10:08:35
 * @FilePath: \ding-lims\src\views\Statistics\DataEquipment.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="DataDetectionTask">
    <van-nav-bar
      title="检测任务分析"
      left-text="返回"
      right-text="筛选"
      left-arrow
      @click-left="back"
      @click-right="chooseshow = true"
    />
    <div class="list-data" v-for="(item, idx) in dataList" :key="idx">
      <h3>{{item.type_name}}</h3>
      <p>{{item.type_name}}</p>
      <ve-table class="item mt10"
        :columns="columns"
        :table-data="item.monthList"
        scrollWidth="720px"
        :border-around="true"
        :border-x="true"
        :border-y="true" />
    </div>
    
    <van-popup round v-model="chooseshow" position="top">
      <van-field name="select_year" label="年度选择"
        v-model="params.select_year"
        input-align="right"
        readonly
        clickable 
        @click-input="timeShow = true"/>
      <van-button class="btn" round block size="small" type="primary" @click="toSure()">确定</van-button>
    </van-popup>

    <!-- 日期选择 --> 
    <van-popup v-model="timeShow" position="bottom">
      <van-datetime-picker type="year-month"
        :min-date="minDate"
        :max-date="maxDate"
        @confirm="timeConfirm"
        @cancel="timeShow = false"
      />
    </van-popup>
</div>
</template>
<script>
import Vue from 'vue'
import { NavBar, Button, Popup, Field, DatetimePicker } from 'vant'
import { VePagination } from "vue-easytable"

Vue.use(NavBar)
Vue.use(Button)
Vue.use(Popup)
Vue.use(Field)
Vue.use(DatetimePicker)
Vue.use(VePagination)

export default {  
  name: 'DataInspector',
  components: {},
  data() {
    return {
      params: {
        select_year: new Date().getFullYear()
      },
      dataStatus: '',
      dataList: [],
      columns: [
        { field: "check_year", key: "a", title: "年", align: "center", width: "30px" },
        { field: "check_month", key: "b", title: "月", align: "center", width: "30px"},
        { field: "hgl", key: "c", title: "合格率(%)", align: "center", width: "50px" },
        { field: "check_num", key: "d", title: "检测次数", align: "center", width: "50px"},
        { field: "qualified_num", key: "e", title: "合格次数", align: "center", width: "50px" },        
      ],
      cellSpanOption: {
        bodyCellSpan: this.bodyCellSpan,
      },
      minDate: new Date(2021, 0, 1),
      maxDate: new Date(),
      chooseshow: false,
      timeShow: false
    }
  },
  created: function () {
    this.getData()
  },
  mounted() { },
  methods: {
    getData() {
      this.$get('statistics/get-check-month-sta', 'api', this.params).then(res => {
        const datas = res.data
        this.dataList = datas
      }, err => {
        this.dataList = []
        console.log(err)
      })
    },
    // 日期确认
    timeConfirm(time) {
      this.params.select_year = time.getFullYear()
      this.timeShow = false
    },
    toSure() {
      this.chooseshow = false
      this.getData()
    },
    back() {
      this.$router.push({ path: '/statistics' })
    }
  }
}
</script>

<style scoped>
.btn{
  width: 90%;
  margin: 20px 5%;
}
.ve-pagination{
  background: transparent;
}
.list-data{
  width: 100%;
}
.list-data h3{
  text-align: center;
  font-size: 16px;
  padding: 0;
  margin: 15px auto 0;
}
.list-data p{
  text-align: center;
  color: #999999;
  font-size: 14px;
  margin: 0 auto 5px;
}
</style>
